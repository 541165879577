import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { scenariosData } from 'data/scenarios-data';
import { landingpageData } from 'data/landingpage-data';
import { generalUiTexts } from 'data/ui-texts/general-ui-texts';
import { loginUiTexts } from 'data/ui-texts/login-ui-texts';
import { getText } from 'helpers/language-helper';
import Button from 'components/ui/button/button';
import TrainingGamesBtn from 'components/ui/trainingGamesBtn/trainingGamesBtn';
import './start-game.scss';

const StartGame = ({deviceInfo, handleGoToGamePage, resetPlayerGameData}) => {

	return (
		<div className='StartGame'>
			<div className='StartGame-grill'/>
			<div className='StartGame-textWrapper'>
				<div className='StartGame-title'>
					<span>{landingpageData.title}</span>
				</div>
				<div className='StartGame-text1'>
					<span>{landingpageData.text1}</span>
				</div>
			</div>

			<div className='StartGame-header'/>
			
			<div className='StartGame-footer'>
				{/* Reset btn */}
				{(appConfig.env === 'development' || appConfig.env === 'test') &&
					<div className="StartGame-resetBtn">
						<Button
							text={getText(loginUiTexts.reset, 'da')}
							classes={['blue']}
							onClick={() => {resetPlayerGameData();}}
						/>
					</div>
				}
				<div className='StartGame-startButton'>
					<Button
						text={getText(generalUiTexts.start, 'da')}
						classes={['blue']}
						onClick={() => {handleGoToGamePage('module', scenariosData[0].modulesData[0].id);}}
					/>
				</div>
				<TrainingGamesBtn />
			</div>
		</div>
	);
};

StartGame.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	handleGoToGamePage: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
};

export default StartGame;