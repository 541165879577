import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { endpageData } from 'data/endPage-data';
import apiHelper from 'helpers/api-helper';
import { getText } from 'helpers/language-helper';
import { renderMarkdown } from 'helpers/text-helper';
import { checkIfValidEmail } from 'helpers/admin-helper';
import { errorUiTexts } from 'data/ui-texts/error-ui-texts';
import { playerUiTexts } from 'data/ui-texts/player-ui-texts';
import { generalUiTexts } from 'data/ui-texts/general-ui-texts';
import TrainingGamesBtn from 'components/ui/trainingGamesBtn/trainingGamesBtn';
import Character from 'components/ui/character/character';
import Button from 'components/ui/button/button';
import './end-game.scss';

const EndGame = ({handleGoToGamePage, handleReset, deviceInfo}) => {
	const [showRaffleEntry, setShowRaffleEntry] = useState(false);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [feedback, setFeedback] = useState(null);
	const [hasNewsletterAccept, setHasNewsletterAccept] = useState(false);

	const handleInput = (event) => {
		let value = event.target.value;
		setEmail(value);
	};

	/**
	 * Toggles checkbox
	 */
	const toggleCheckbox = () => {
		setHasNewsletterAccept(!hasNewsletterAccept);
	};

	/**
	 * Handle raffle submit
	 * @param {object} event 
	 * @returns 
	 */
	const handleSubmitEmail = (event) => {
		if (event) {
			event.preventDefault();
		}
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		let feedback = null;
		
		const isValid = checkIfValidEmail(email);

		if (email && !isValid.isValidEmail) {
			feedback = getText(errorUiTexts.errInvalidEmail, 'da');
		}

		if (isValid) {
			apiHelper('player/send-raffle-mail', {
				toEmail: appConfig.raffleEmail,
				email: email.toLowerCase(), 
				joinedNewsletter: hasNewsletterAccept
			}).then((response) => {
				setIsLoading(false);
				if (response.status === 'success') {
					setShowRaffleEntry(false);
				} else if (response.status === 'error') {
					setFeedback(getText(errorUiTexts.unknownError, 'da'));
				}
			});
		}

		setFeedback(feedback);
	};

	return (
		<div className={'EndGame ' + deviceInfo.orientation}>
			<div className='EndGame-grill'/>
			<div className='EndGame-textWrapper'>
				<div className='EndGame-text1'>
					{renderMarkdown(endpageData.text1)}
				</div>
			</div>

			<div className='EndGame-manager'>
				<Character page={''} deviceInfo={deviceInfo} characterId={'manager pose-1'} />
			</div>

			<div className='EndGame-header'/>
			
			<div className="EndGame-homeBtn" onClick={() => {handleGoToGamePage('startGame');}} />

			<div className='EndGame-retryBtn'>
				<Button
					text={getText(generalUiTexts.retry, 'da')}
					classes={['blue']}
					onClick={() => {handleReset();}}
				/>
			</div>
			<TrainingGamesBtn />

			{showRaffleEntry &&
				<div className='EndGame-rafflePopupWrapper' onClick={() => {setShowRaffleEntry(false);}}>
					<div className='EndGame-raffleWindow' onClick={(e) => {e.stopPropagation();}}>
						<div className='EndGame-raffleHeader'>
							<span>{getText(generalUiTexts.raffleTitle, 'da')}</span>
						</div>
						
						<form className="EndGame-form" onSubmit={(e) => {handleSubmitEmail(e);}}>
							<input
								name="email"
								type="text"
								placeholder={getText(playerUiTexts.playerInfoEmail, 'da')} 
								autoComplete="section-playerInfo email"
								value={email ? email : ''}
								onChange={(event)=>{handleInput(event);}}
							/>
							<div className='EndGame-checkboxWrapper'>
								<div className='EndGame-checkboxText'>
									<span>{getText(generalUiTexts.registerNewsletter, 'da')}</span>
								</div>
								<div
									className={'EndGame-newsletterCheckbox ' + (hasNewsletterAccept ? 'checked' : '')}
									onClick={() => {toggleCheckbox();}}
								/>
							</div>
							<div className="EndGame-submitBtn">
								<Button 
									isDisabled={isLoading || email.length <= 0}
									isLoading={isLoading}
									type="submit"
									classes={['login']}
									text={getText(generalUiTexts.submit, 'da')}
									onClick={(e) => {handleSubmitEmail(e);}}
								/>
							</div>
							<div className="EndGame-errorMessage"><span>{feedback}</span></div>
						</form>
					</div>
				</div>
			}
		</div>
	);
};

EndGame.propTypes = {
	handleGoToGamePage: PropTypes.func.isRequired,
	handleReset: PropTypes.func.isRequired,
	deviceInfo: PropTypes.object.isRequired,
};

export default EndGame;